import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Transition from '../components/Transition/Transition'
import './all.scss'
import CustomNav from './CustomNav'
import CustomFooter from './CustomFooter/CustomFooter'
import { createGlobalStyle } from 'styled-components'

import fav from '../images/favicon.ico'

const GlobalStyles = createGlobalStyle`
    *, body {
    font-family: Poppins, serif;
  }
`

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]')
}

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            keywords
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content: data.site.siteMetadata.description,
            },
            { name: 'keywords', content: data.site.siteMetadata.keywords },
          ]}
          link={[
            {
              rel: 'shortcut icon',
              href: `${fav}`,
            },
          ]}
          bodyAttributes={{ class: '' }}
        >
          <html lang="en" />
        </Helmet>
        <div className="site">
          <GlobalStyles />
          <CustomNav />
          <div className="site-content">
            <Transition location={location}>{children}</Transition>
          </div>
          <CustomFooter />
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
