import styled from 'styled-components'
import { mediumBreakpoint } from '../../config/css-constants'
import { movingBall, scaling } from '../../assets/keyframes/keyframes'

export const FooterDots = styled.div`
  display: block;
  @media all and (min-width: ${mediumBreakpoint}) {
    display: none;
  }
`
export const FooterFirstDot = styled.div`
  height: 55px;
  width: 55px;
  background: #f9c255;
  border-radius: 50%;
  position: absolute;
  left: 5%;
  top: -5%;
  animation-name: ${movingBall};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`

export const FooterSecondDot = styled.div`
  height: 40px;
  width: 40px;
  background: #f9c255;
  border-radius: 50%;
  position: absolute;
  right: 5%;
  top: 50%;
  animation-name: ${scaling};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`

export const FooterThirdDot = styled.div`
  height: 20px;
  width: 20px;
  background: #f9c255;
  border-radius: 50%;
  position: absolute;
  right: 15%;
  bottom: 10%;
  animation-name: ${scaling};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`
