import React, { Component } from 'react'
import { Container, Hero, HeroBody } from 'bloomer'
import logotypeMobile from './../../images/footer_logo.png'
import logotypeMobileWebp from './../../images/stopkalogo-533374.webp'
import styled from 'styled-components'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFacebookSquare,
  faGithub,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { mediumBreakpoint } from '../../config/css-constants'
import {
  FooterFirstDot,
  FooterDots,
  FooterSecondDot,
  FooterThirdDot,
} from './styles'

library.add(faFacebookSquare, faGithub, faLinkedin)

const CustomImage = styled.img`
  display: none;
  @media all and (min-width: ${mediumBreakpoint}) {
    margin: auto;
    width: 160px;
    height: 123px;
    display: block;
    position: relative;
    top: -11px;
  }
`

const CustomHero = styled(Hero)`
  padding-top: 1.5rem;
`

const CustomHeroBody = styled(HeroBody)`
  padding: 1rem 1.5rem;
  text-align: center;
  background-color: #fec256;
  font-size: 14px;
  color: #13253b;
  text-decoration: none solid rgb(19, 37, 59);
  letter-spacing: 1px;
`

const CustomFooterLogotypes = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: center;
  @media all and (min-width: ${mediumBreakpoint}) {
    justify-content: unset;
  }
  svg {
    color: #4a4a4a;
    max-height: 32px;
  }
  a {
    width: 28px;
    height: 32px;
  }
  a:nth-of-type(2) {
    width: 32px;
  }
  a:nth-child(n + 2) {
    margin-left: 10px;
  }
`

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 30px;
  @media all and (min-width: ${mediumBreakpoint}) {
    flex-direction: unset;
  }
`

const Column = styled.div`
  width: 100%;
  text-align: center;
  @media all and (min-width: ${mediumBreakpoint}) {
    width: 25%;
    text-align: unset;
  }
`

class CustomFooter extends Component {
  render() {
    return (
      <footer className={'h-card'}>
        <Container>
          <FooterDots>
            <FooterFirstDot />
            <FooterSecondDot />
            <FooterThirdDot />
          </FooterDots>
          <Columns>
            <Column>
              <picture>
                <source srcSet={logotypeMobileWebp} type="image/webp" />
                <source srcSet={logotypeMobile} type="image/jpeg" />
                <CustomImage
                  className={'u-logo'}
                  src={logotypeMobile}
                  alt={'Deployed Logo'}
                  title={'Deployed Logo'}
                />
              </picture>
            </Column>
            <Column>
              <p style={{ marginBottom: '5px' }}>
                We are here to help <b>YOU</b>
              </p>
              <p className={'u-email'}>
                <a className={'footer-link'} href="mailto:hello@deployed.pl">
                  hello@deployed.pl
                </a>
              </p>
            </Column>
            <Column>
              <p style={{ marginBottom: '5px' }}>Address:</p>
              <p className={'p-name'}>deployed.pl</p>
              <p>
                <span className={'p-street-address'}>Juliusza Lea 114</span>
                <br/>
                <span className={'locality'}>30-133 Cracow</span>
              </p>
              <p className={'p-country-name'}>Poland</p>
            </Column>
            <Column>
              <p>Find us also:</p>
              <CustomFooterLogotypes>
                <a
                  href={'https://www.facebook.com/deployedpl/'}
                  target={'_BLANK'}
                  title={'facebook page'}
                >
                  <FontAwesomeIcon
                    icon={{ prefix: 'fab', iconName: 'facebook-square' }}
                    size={'2x'}
                  />
                </a>
                <a
                  href={'https://github.com/deployed/'}
                  target={'_BLANK'}
                  title={'github page'}
                >
                  <FontAwesomeIcon
                    icon={{ prefix: 'fab', iconName: 'github' }}
                    size={'2x'}
                  />
                </a>
                <a
                  href={'https://www.linkedin.com/company/deployedpl/'}
                  target={'_BLANK'}
                  title={'linkedin page'}
                >
                  <FontAwesomeIcon
                    icon={{ prefix: 'fab', iconName: 'linkedin' }}
                    size={'2x'}
                  />
                </a>
              </CustomFooterLogotypes>
            </Column>
          </Columns>
        </Container>
        <CustomHero>
          <CustomHeroBody>
            &copy; {new Date().getFullYear()} deployed.pl All rights reserved.
          </CustomHeroBody>
        </CustomHero>
      </footer>
    )
  }
}

export default CustomFooter
