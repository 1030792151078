import { keyframes } from 'styled-components'

const scalingRange = 1.1

export const draw = keyframes`
  85% {
      fill: white;
  }
  100% {
      stroke-dashoffset: 0;
      fill: #FFC34A;
  }
`

export const opacity = keyframes`
  0% {
    fill-opacity: 0;
  }
  50% {
      fill-opacity: 1;
  }
  100% {
      fill-opacity: 1;
  }
`

export const fallingDown = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
`

export const showingUpPaperIco = keyframes`
  0% {
    opacity: 0;
    right: 18%;
    top: 54%;
  }
  100% {
    opacity: 1;
    right: 25%;
    top: 25%;
  }
`

export const showingUpHandIco = keyframes`
  0% {
    opacity: 0;
    right: 25%;
    top: 18%;
  }
  100% {
    opacity: 1;
    right: 10%;
    top: 10%;
  }
`

export const scaling = keyframes`
  0% {
    transform: scale(1)
  }
  50% {
    transform: scale(${scalingRange})
  }
  100% {
    transform: scale(1);
  }
`

export const movingBall = keyframes`
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(5px, 10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
`

export const movingBallReverse = keyframes`
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(-5px, 10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
`

export const fadeFromLeft = keyframes`
  0%{
    transform: translateX(-100%);
    opacity: 0;
  }
  100%{
    transform: translateX(0);
    opacity: 1;
  }
`

export const fadeFromRight = keyframes`
  0%{
    transform: translateX(100%);
    opacity: 0;
  }
  100%{
    transform: translateX(0);
    opacity: 1;
  }
`

export const fadeFromRightException = keyframes`
  0%{
    transform: translateX(100%);
    opacity: 0;
  }
  100%{
    transform: translateX(-50%);
    opacity: 1;
  }
`

export const moveLeftRight = keyframes`
  0%{
    opacity: 0;
    
  }
  20% {
    opacity: 0;
    transform: translateX(-50%);
  }
  50%{
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100%{
    transform: translateX(250%);
    opacity: 0;
  }
 `

export const opacityToZero = keyframes`
    0%   {opacity: 1;}
    98%  {opacity: 1;}
    100% {opacity: 0;}

`

export const cloudsAnimationLeft = keyframes`
    0%   {
      transform: translateX(0);
    }
    30%  {
      transform: translateX(-10%);
    }
    70% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(0);
    }
`

export const cloudsAnimationRight = keyframes`
    0%   {
     transform: translateX(0);
    }
    50%  {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(0);
    }
`

export const fadeFromLeftAndRotate = keyframes`
  0%{
    transform: translateX(-100%);
    opacity: 0;
  }
  50% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
  }
  100%{
    transform: translateX(0);
    opacity: 1;
  }
`

export const rotateLeftWheel = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const rotateRightWheel = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
`

export const leftAndRight = keyframes`
  0%{
    transform: translateX(0);
  }
  10% {
    transform: translateX(0);
  } 
  50% {
    transform: translateX(30%);
  }
  90% {
    transform: translateX(0);
  }
  100%{

  }
 `

export const rotate360 = keyframes`
  0%{
    transform: rotate(0) scale(0.3);
  }
  50% {
    transform: rotate(-180) scale(1);
  }
  100%{
    transform: rotate(-360deg);
  }
`

export const rotate360reverse = keyframes`
  0%{
    transform: rotate(0) scale(0.3);
  }
  50% {
    transform: rotate(180) scale(1);
  }
  100%{
    transform: rotate(360deg);
  }
`
