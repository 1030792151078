import React, { Component } from 'react'
import { Link } from 'gatsby'
import logotypeDesktop from './../../images/15_08-0458ad.png'
import logotypeMobile from './../../images/headermobilelogo.png'
import {
  fullhd,
  largeBreakpoint,
  mediumBreakpoint,
} from '../../config/css-constants'
import styled from 'styled-components'
import {
  Container,
  NavbarBrand,
  NavbarBurger,
  NavbarEnd,
  NavbarItem,
  NavbarMenu,
  NavbarStart,
} from 'bloomer'

const Header = styled.header`
  position: fixed;
  width: 100%;
  z-index: 99;
  background: #fff;
  @media all and (min-width: ${largeBreakpoint}) {
    position: static;
  }
`
const CustomNavbar = styled(NavbarItem)`
  padding-top: 10px;
  padding-bottom: 0;
  font-size: 24px;
  color: #14253a;
  line-height: 36px;
  letter-spacing: 1px;
  &:last-of-type {
    color: #e01c27;
  }
`

const LogoLink = styled(Link)`
  display: flex;
`;

const CustomNavbarEnd = styled(NavbarEnd)`
  text-align: center;
`

const StyledLink = styled((props) => <Link {...props} />)`
  font-size: 1.1rem;
  color: #14253a;
  line-height: 36px;
  letter-spacing: 1px;
  padding: 0.75rem;
  padding-bottom: 0;
  display: inline-block;
  position: relative;
  text-align: center;

  &:after {
    display: block;
    content: '';
    border-bottom: solid 1px #e54541;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    margin-bottom: -1px;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: 0% 100%;
  }

  &:last-of-type {
    padding-right: 0;
  }

  @media all and (min-width: ${mediumBreakpoint}) {
    padding: 0.75rem;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    font-size: 1.4rem;
  }
  @media all and (min-width: ${fullhd}) {
    margin-right: 4rem;
  }
`

class CustomNav extends Component {
  constructor(props) {
    super(props)
    this.state = { isActive: false }
  }

  onClickNav = () => {
    this.setState({ isActive: !this.state.isActive })
  }

  isMenuItemActive = ({ isCurrent }) => {
    if (!isCurrent) return null
    return {
      style: {
        color: '#E54541',
        borderBottom: 'solid 1px #e54541',
      },
    }
  }

  render() {
    return (
      <Header>
        <Container isFluid className={'custom_container'}>
          <CustomNavbar>
            <NavbarBrand>
              <NavbarItem isHidden="touch">
                <LogoLink to={'/'}>
                  <img
                    className="navbar-logotype"
                    src={logotypeDesktop}
                    alt={'Deployed Logo'}
                  />
                </LogoLink>
              </NavbarItem>
              <NavbarItem isHidden="desktop">
                <Link to={'/'}>
                  <img src={logotypeMobile} alt={'Deployed Company'} />
                </Link>
              </NavbarItem>
              <NavbarBurger
                isActive={this.state.isActive}
                onClick={this.onClickNav}
              />
            </NavbarBrand>
            <NavbarMenu
              isActive={this.state.isActive}
              onClick={this.onClickNav}
            >
              <NavbarStart />
              <CustomNavbarEnd>
                <StyledLink getProps={this.isMenuItemActive} to="/">
                  home
                </StyledLink>
                <StyledLink getProps={this.isMenuItemActive} to="/offer">
                  offer
                </StyledLink>
                <StyledLink getProps={this.isMenuItemActive} to="/how-we-work">
                  about
                </StyledLink>
                <StyledLink getProps={this.isMenuItemActive} to="/portfolio">
                  portfolio
                </StyledLink>
                <StyledLink getProps={this.isMenuItemActive} to="/career">
                  career
                </StyledLink>
                <StyledLink getProps={this.isMenuItemActive} to="/contact">
                  contact
                </StyledLink>
                <StyledLink getProps={this.isMenuItemActive} to="/blog">
                  blog
                </StyledLink>
              </CustomNavbarEnd>
            </NavbarMenu>
          </CustomNavbar>
        </Container>
      </Header>
    )
  }
}

export default CustomNav
